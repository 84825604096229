@import '~antd/dist/antd.css';

.ant-checkbox + span,
.ant-radio + span {
  width: 100%;
}

.has-error .ant-checkbox-group .ant-input[disabled],
.has-error .ant-radio-group .ant-input[disabled] {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
